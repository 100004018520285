import React from 'react';
import './App.css';
import queryString from 'query-string';
import QRCode from 'qrcode'
import { useAsync } from 'react-use';
interface InputParams {
  mhcId: string
  prefix?: string
  height?: number
  width?: number
  offsetX?: number
  offsetY?: number
  debug?: boolean
}

interface RenderParams extends Required<InputParams> {
  qrDataUrl: string
}
async function genRenderParams(): Promise<RenderParams> {
  const { params = "" } = queryString.parse(window.location.search) as any
  const { mhcId, prefix = "",
    height = 150, width = 300,
    offsetX = 0, offsetY = 0,
    debug = false
  }: InputParams = JSON.parse(window.decodeURIComponent(params))
  if (mhcId.length !== 16) throw new Error(`mhcId 必须为 16 字符长度，当前 mhcId = ${mhcId}`)
  const qrDataUrl = await QRCode.toDataURL(mhcId, { margin: 0 })
  return {
    mhcId, prefix,
    height, width,
    offsetX, offsetY,
    debug,
    qrDataUrl
  }
}


function App() {
  const { loading, value, error } = useAsync(genRenderParams)

  if (loading) {
    return <div style={{
      fontSize: 12,
      padding: "10px"
    }}>
      <div>
        加载中
      </div>
    </div>
  }
  if (error) {
    return <div style={{
      fontSize: 12,
      padding: "10px"
    }}>
      <div>
        渲染出错
        </div>
      <div>
        {error!.stack!.split("\n").slice(0, 3).join("\n")}
      </div>
    </div>
  }

  if (value) {
    const { mhcId, prefix,
      height, width,
      offsetX, offsetY,
      debug,
      qrDataUrl
    } = value
    return (
      <div style={{
        padding: "10px",
        height: height + "px",
        width: width + "px",
        marginTop: offsetY + "px",
        marginLeft: offsetX + "px",
        border: debug ? "4px solid black" : "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
      }}>
        <div style={{
          fontSize: "18px", width: 160, height: 100, fontWeight: 900,
          display: "flex", flexDirection: "column", justifyContent: "space-around"
        }}>
          <div>{prefix + mhcId}</div>
          <div style={{ fontSize: "50px", fontWeight: 900, textAlign: 'center' }}>{mhcId.slice(12)}</div>
        </div>
        <img
          style={{ width: 100, height: 100 }}
          src={qrDataUrl}
          alt=""
           />
      </div>
    );
  }
  return null
}

export default App;
